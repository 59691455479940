import axios from "axios"
import { SimpleMessage } from "../../SimpleMessage"

const useLLM = () => {
    const fetchLLM = async (
        namespace: string,
        question: string,
        sessionId: string,
        history: SimpleMessage[]
    ) => {
        // call the backend using axios
        // return the response
        const response = await axios.post(
            "https://glimelab-bot-backend.onrender.com/sandbox",
            {
                namespace,
                question,
                sessionId,
                history,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )

        return response.data
    }

    return {
        fetchLLM,
    }
}

export { useLLM }
