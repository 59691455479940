import React, { useRef, useEffect } from "react"
import faker from "faker"

// TODO: add dynamic data for the messages

import ChannelMessage, { Mention } from "../ChannelMessage"
import Message from "../Layout/index"

import {
    Container,
    Messages,
    InputContainer,
    InputWrapper,
    Input,
    InputIcon,
} from "./styles"

interface ChannelDataProps {
    messages?: any
    onSendMessage: (message: string, cb: () => void) => void
}

const ChannelData: React.FC<ChannelDataProps> = ({
    messages,
    onSendMessage,
}) => {
    const messagesRef = useRef() as React.MutableRefObject<HTMLDivElement>

    useEffect(() => {
        const div = messagesRef.current

        if (div) {
            div.scrollTop = div.scrollHeight
        }
    }, [messagesRef])

    return (
        <Container>
            <Messages ref={messagesRef}>
                {messages.map((message: any) => (
                    <ChannelMessage
                        key={message.id}
                        author={message.author}
                        date={message.date}
                        content={message.content}
                        hasMention={message.hasMention}
                        continuation={message.continuation ?? false}
                        isHTML={message.isHTML ?? false}
                        sources={message.sources ?? []}
                    />
                ))}
            </Messages>

            <InputContainer>
                <InputWrapper>
                    <Input
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault()

                                onSendMessage(e.currentTarget.value, () => {
                                    const div = messagesRef.current
                                    div.scrollTop = div.scrollHeight
                                })

                                e.currentTarget.value = ""
                            }
                        }}
                        type="text"
                        placeholder="@Glime, how to use this project?"
                    />
                    <InputIcon />
                </InputWrapper>
            </InputContainer>
        </Container>
    )
}

export default ChannelData
