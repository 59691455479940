import React, { useEffect, useRef } from "react"
import { Grid } from "./styles"

import ServerList from "../ServerList"
import ServerName from "../ServerName"
import ChannelInfo from "../ChannelInfo"
import ChannelList from "../ChannelList"
import UserInfo from "../UserInfo"
import ChannelData from "../ChannelData"
import UserList from "../UserList"
import _, { forEach } from "underscore"
import WelcomeModal from "../WelcomeModal/WelcomeModal"
import { SimpleMessage } from "../../SimpleMessage"

import langchain from "../../assets/images/servers/langchain.webp"
import alchemy from "../../assets/images/servers/alchemy.webp"

import * as Avatars from "../../lib/Avatars/Avatars"

import ChannelMessage, { Mention } from "../ChannelMessage"

import "highlight.js/styles/hybrid.css"

import { useLLM } from "../../hooks/UseLLM/UseLLM"

// @ts-ignore
import Highlight from "react-highlight"
import { messageToString } from "../../lib/Helpers/Helpers"

import hljs from "highlight.js/lib/core"

import { useFirebase } from "../../hooks/UseFirebase/UseFirebase"

import { v4 as uuid } from "uuid"

import javascript from "highlight.js/lib/languages/javascript"
import typescript from "highlight.js/lib/languages/typescript"
import python from "highlight.js/lib/languages/python"
import java from "highlight.js/lib/languages/java"
import c from "highlight.js/lib/languages/c"
import php from "highlight.js/lib/languages/php"
import csharp from "highlight.js/lib/languages/csharp"
import css from "highlight.js/lib/languages/css"
import ReactMarkdown from "react-markdown"

hljs.registerLanguage("javascript", javascript)
hljs.registerLanguage("typescript", typescript)
hljs.registerLanguage("python", python)
hljs.registerLanguage("java", java)
hljs.registerLanguage("c", c)
hljs.registerLanguage("php", php)
hljs.registerLanguage("csharp", csharp)
hljs.registerLanguage("css", css)

const MAX_HISTORY_MESSAGES = 5

function generateMarkdown(urls: string[]) {
    var markdown = ""

    for (var i = 0; i < urls.length; i++) {
        var url = urls[i]
        var segments = url.split("/")
        var lastSegment = segments[segments.length - 1]

        markdown += "[" + lastSegment + "](" + url + ")"
    }

    return markdown
}

var sessionId = uuid()
const { logEvent, initialize } = useFirebase()
initialize(sessionId)

export type MessageAuthor = {
    nickname: string
    avatar: string
    role: string
    isBot: boolean
}

export type Message = {
    id: string
    author: MessageAuthor
    date: string
    content: string | React.ReactElement | React.ReactNode
    hasMention?: boolean
}

type LLMResponse = {
    answer: string
    sources: string[]
}

export const getMember = (nickname: string): MessageAuthor => {
    const database = [
        Avatars.Glime,
        Avatars.AryaStark,
        Avatars.CerseiLannister,
        Avatars.DaenerysTargaryen,
        Avatars.JonSnow,
        Avatars.NedStark,
        Avatars.JackieRobinson,
        Avatars.Harry,
        Avatars.Hermione,
        Avatars.Ron,
        Avatars.Draco,
        Avatars.Snape,
        Avatars.Minerva,
        Avatars.Nibbler,
        Avatars.Bender,
        Avatars.Hubert,
        Avatars.Phillip,
        Avatars.Turanga,
        Avatars.Doctor,
        Avatars.Zapp,
        Avatars.Seinfeld,
        Avatars.Eleine,
        Avatars.Kramer,
        Avatars.Newman,
        Avatars.Soup,
    ]

    console.log(
        database.filter((member) => member.nickname === nickname)[0] ?? null
    )

    return database.filter((member) => member.nickname === nickname)[0] ?? null
}

const servers = [
    {
        actualInitialChatMessageIndex: -1,
        hasNotifications: false,
        mentions: 0,
        id: "1",
        name: "Moonstream",
        image: "https://avatars.githubusercontent.com/u/88054195?s=200&v=4",
        namespace: "moonstream",
        channels: [
            {
                name: "general",
            },
            {
                name: "announcements",
            },
            {
                name: "support",
                selected: true,
            },
            {
                name: "feature-requests",
            },
            {
                name: "off-topic",
            },
        ],
        members: [
            Avatars.Glime,
            Avatars.JackieRobinson,
            Avatars.AryaStark,
            Avatars.CerseiLannister,
            Avatars.DaenerysTargaryen,
            Avatars.JonSnow,
            Avatars.NedStark,
        ],
        messages: [
            // {
            //     id: "1",
            //     author: getMember("Arya Stark"),
            //     date: "02/06/2023",
            //     content: (
            //         <>
            //             <Mention>@Glime</Mention>, can you give me the firsts
            //             steps to integrate my game with Moonstream APIs?
            //         </>
            //     ),
            // },
            // {
            //     id: "3",
            //     author: getMember("Glime"),
            //     date: "02/06/2023",
            //     content: (
            //         <>
            //             <p>To integrate with Moonstream APIs, you need to...</p>

            //             <p>
            //                 <Highlight language="python">{`[ADD ANSWER HERE]`}</Highlight>
            //             </p>
            //         </>
            //     ),
            //     continuation: true,
            // },
            {
                id: "4",
                author: getMember("Glime"),
                date: new Date().toLocaleDateString(),
                content: (
                    <>
                        <Mention>@Jackie Robinson</Mention>, I'm Glime! Your
                        code assistant buddy. and this is an experimental
                        environment. Feel free to ask me any question related to
                        the current server where in.
                    </>
                ),
                hasMention: true,
            },
        ] as Message[],
    },
]

servers.forEach((server) => {
    server.actualInitialChatMessageIndex = server.messages.length
})

const botGreetings = (user: string) => {
    return {
        id: String(Math.random()),
        author: getMember("Glime"),
        date: new Date().toLocaleDateString(),
        content: (
            <>
                Hold on, <Mention>{user}</Mention>! I'll answer in a couple
                seconds.
            </>
        ),
        hasMention: true,
    }
}

const botAnswer = (answer: string, sources: string[]) => {
    return {
        id: String(Math.random()),
        author: getMember("Glime"),
        date: new Date().toLocaleDateString(),
        content: answer,
        continuation: false,
        isHTML: true,
        sources: sources,
    }
}

const Layout: React.FC = () => {
    const [selectedServer, setSelectedServer] = React.useState(servers[0])
    const [lastUpdate, setLastUpdate] = React.useState(String)
    const [showModal, setShowModal] = React.useState(true)

    const lastGreetingIdRef = useRef<string>()

    useEffect(() => {
        hljs.highlightAll()
    }, [selectedServer])

    const { fetchLLM } = useLLM()

    return (
        <>
            {showModal && (
                <WelcomeModal
                    onCloseModal={() => {
                        logEvent("modal_closed", {
                            sessionId: sessionId,
                            origin: window.location.hostname,
                        })
                        setShowModal(false)
                    }}
                ></WelcomeModal>
            )}
            <Grid>
                <ServerList
                    onServerSelected={(id) =>
                        setSelectedServer(
                            servers.filter((server) => server.id === id)[0]
                        )
                    }
                    servers={servers}
                />
                <ServerName name={selectedServer.name} />
                <ChannelInfo />
                <ChannelList channels={selectedServer.channels} />
                <UserInfo />
                <ChannelData
                    onSendMessage={(message, cb) => {
                        logEvent("prompt_sent", {
                            sessionId: sessionId,
                            origin: window.location.hostname,
                        })

                        selectedServer.messages.push({
                            id: String(Math.random()),
                            author: getMember("Jackie Robinson"),
                            date: new Date().toLocaleDateString(),
                            content: message,
                        })

                        setLastUpdate(String(Math.random()))

                        setTimeout(() => {
                            cb()

                            setTimeout(async () => {
                                const historyMessages: SimpleMessage[] = []

                                selectedServer.messages
                                    .slice(
                                        selectedServer.actualInitialChatMessageIndex -
                                            1
                                    )
                                    .reverse()
                                    .slice(1, MAX_HISTORY_MESSAGES)
                                    .forEach((message: Message) => {
                                        historyMessages.push({
                                            author: message.author.isBot
                                                ? "BOT"
                                                : "USER",
                                            content: messageToString(
                                                message.content
                                            ),
                                        })
                                    })

                                const greetingMessage = botGreetings(
                                    `@${getMember("Jackie Robinson").nickname}`
                                )

                                lastGreetingIdRef.current = greetingMessage.id

                                // add greeting from bot
                                selectedServer.messages.push(greetingMessage)

                                setLastUpdate(String(Math.random()))

                                cb()

                                let m: LLMResponse = await fetchLLM(
                                    selectedServer.namespace!!,
                                    message,
                                    sessionId,
                                    historyMessages
                                )

                                // Remove last greeting message
                                selectedServer.messages =
                                    selectedServer.messages.filter(
                                        (message) =>
                                            message.id !==
                                            lastGreetingIdRef.current
                                    )

                                selectedServer.messages.push(
                                    botAnswer(m.answer, m.sources)
                                )

                                setLastUpdate(String(Math.random()))

                                hljs.highlightAll()

                                cb()
                            }, 500)

                            // call backend

                            // add response from bot
                        }, 200)
                    }}
                    messages={selectedServer.messages}
                />
                <UserList members={selectedServer.members} />
            </Grid>
        </>
    )
}

export default Layout
